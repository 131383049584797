import { Helmet } from "react-helmet";
import Layout from "../layout";
import React, { useContext, useState } from "react";
import SeoHeaders from "../../components/seo/seo-headers";
import { websiteName } from "../../settings";
import ImageGallery from "react-image-gallery";
import { addWidthParameter } from "../../utils/addWidthParameter";
import { useBodyClass } from "../../utils/bodyClassUtils";
import PageBlockNewsletter from "../../components/page-blocks/page-block-newsletter";
import PageBlockHeader from "../../components/page-blocks/page-block-header";
import GlobalContext from "../../contexts/global-provider";

export default ({ pageContext: { page, blogPost, navigation, cruiseTypes, privacy } }) => {
  const { setNavigation, setPrivacy, theme } = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  if (!blogPost) {
    return <></>;
  }

  const galleryImages = [];

  const newsLetterData = {
    content: {
      general: {
        conformationEmailTemplate: blogPost.content.newsletter.conformationEmailTemplate,
        text: blogPost.content.newsletter.text,
      },
    },
  };

  blogPost.content.general.gallery.forEach(image => {
    galleryImages.push({
      original: addWidthParameter(image.url, 1350),
      thumbnail: addWidthParameter(image.url, 200),
    });
  });

  const [isImageGalleryActive, setIsImageGalleryActive] = useState(false);
  useBodyClass(isImageGalleryActive, "has-overlay");

  const handleScreenchange = () => {
    setIsImageGalleryActive(!isImageGalleryActive);
  };

  let url = "thalassa.be";

  if (typeof window !== "undefined" && window?.location?.href) {
    url = window.location.href;
  }

  // Output

  return (
    <Layout
      isTiny={Boolean(page?.content?.layout.isTiny)}
      hasHeader={true}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={Boolean(page.content.layout.backgroundTinted)}
      cruiseTypes={cruiseTypes}
    >
      <SeoHeaders
        seo={blogPost.content.seo}
        pageTitle={
          blogPost.content.seo && blogPost.content.seo.title ? blogPost.content.seo.title : page.content.general.title
        }
        pagePath={page.content.general.path}
        siteName={websiteName[theme]}
      />
      <Helmet>
        <title>
          {blogPost.content.seo && blogPost.content.seo.title ? blogPost.content.seo.title : page.content.general.title}
        </title>
      </Helmet>
      <PageBlockHeader data={blogPost} buttonLink="/cruise-blog" type="blog" />
      <section className="blogPost">
        <div className="blogPost__container">
          <div className="blogPost__options">
            <div className="blogPost__options--date">{blogPost.content.general.publicationDate}</div>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              target="_blank"
              rel="noopener noreferrer"
              title="delen op facebook"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </div>
          <div className="blogPost__row">
            <div className="blogPost__content">
              <div dangerouslySetInnerHTML={{ __html: blogPost.content.general.text }} />
            </div>
          </div>
          <div className="blogPost__gallery">
            <ImageGallery
              items={galleryImages}
              useBrowserFullscreen={false}
              onScreenChange={handleScreenchange}
              showPlayButton={false}
            />
          </div>
          {blogPost.content?.general?.youtubeIframe && (
            <div
              className="blogPost__video"
              dangerouslySetInnerHTML={{ __html: blogPost.content?.general?.youtubeIframe }}
            ></div>
          )}
        </div>
        <PageBlockNewsletter data={newsLetterData} />
      </section>
    </Layout>
  );
};
